import React, { useLayoutEffect } from "react"
// import Img from "gatsby-image"
// import { useStaticQuery, graphql } from "gatsby"
import style from "./anchors.module.css"

import classNames from "classnames/bind"
// const cx = classNames.bind(style)

import {
    pullContent,
    highlightAnchorLink
} from "../../components/scroll-effects.js"

const cx = classNames.bind(style)

export default function Anchor() {

    useLayoutEffect(() => {
        highlightAnchorLink("#work")
        highlightAnchorLink("#team")
        highlightAnchorLink("#clients")
        highlightAnchorLink("#location")
        highlightAnchorLink("#contact")
    }, [])

    return (
        <>
            <nav className={style.anchors}>
                <button
                    className={style.anchorLink}
                    onClick={() => pullContent("#work")}
                    id="pull-work"
                    aria-label="label"
                >
                    work  &gt;
                </button>

                <button
                    className={style.anchorLink}
                    onClick={() => pullContent("#team")}
                    id="pull-team"
                    aria-label="label"
                >
                    team  &gt;
                </button>

                <button
                    className={style.anchorLink}
                    onClick={() => pullContent("#clients")}
                    id="pull-clients"
                    aria-label="label"
                >
                    clients  &gt;
                </button>

                <button
                    className={style.anchorLink}
                    onClick={() => pullContent("#location")}
                    id="pull-location"
                    aria-label="label"
                >
                    location  &gt;
                </button>

                <button
                    className={cx(style.anchorLink, style.anchorLinkContact)}
                    onClick={() => pullContent("#contact")}
                    id="pull-contact"
                    aria-label="label"
                >
                    contact  &gt;
                </button>

                {/* <button
                    className={style.anchorLink}
                    onClick={() => pullContent("#contact")}
                    id="pull-contact"
                    aria-label="label"
                >
                    contact  &gt;
              </button> */}
            </nav>
        </>
    )
}



// import { useLayoutEffect } from "react"
import ScrollMagic from "scrollmagic"
import "scrollmagic/scrollmagic/minified/plugins/debug.addIndicators.min.js"

export const pageSlowReveal = triggerElement => {
  const instantHide = () =>
    // document.querySelector("#main").classList.add("makeInvisible")
    (document.querySelector("#page").style.opacity = 0)
  const slowReveal = () =>
    setTimeout(() => {
      document.querySelector("#page").style.opacity = 1
    }, 1)
  instantHide()
  slowReveal()
  return () => {
    clearTimeout(slowReveal)
  }
}

export const highlightInterface = triggerElement => {
  const triggerHook = 0.35
  const controller = new ScrollMagic.Controller()
  if (document.querySelector(triggerElement)) {
    return (
      new ScrollMagic.Scene({
        triggerElement: triggerElement,
        triggerHook: triggerHook,
        duration: 1000,
        offset: -96,
      })
        .setClassToggle(
          triggerElement.replace("#wrapper_", "#"),
          "interface_in"
        )
        // .addIndicators({ name: triggerElement })
        .addTo(controller)
    )
  }
}

export const highlightStoryLink = triggerElement => {
  const triggerHook = 0.5
  const controller = new ScrollMagic.Controller()
  setTimeout(() => {
    if (document.querySelector(triggerElement)) {
      return (
        new ScrollMagic.Scene({
          triggerElement: triggerElement,
          triggerHook: triggerHook,
          duration: document.querySelector(triggerElement).clientHeight,
          offset: 0,
        })
          .setClassToggle(
            triggerElement.replace("#", "#pull-"),
            "storyLink_flash"
          )
          // .addIndicators({ name: triggerElement })
          .addTo(controller)
      )
    }
  }, 2000)
  return () => {
    clearTimeout(highlightStoryLink)
  }
}

export const highlightAnchorLink = triggerElement => {
  const triggerHook = 0.5
  const controller = new ScrollMagic.Controller()
  setTimeout(() => {
    if (document.querySelector(triggerElement)) {
      return (
        new ScrollMagic.Scene({
          triggerElement: triggerElement,
          triggerHook: triggerHook,
          duration: document.querySelector(triggerElement).clientHeight,
          offset: 0,
        })
          .setClassToggle(
            triggerElement.replace("#", "#pull-"),
            "anchorLink_flash"
          )
          // .addIndicators({ name: triggerElement })
          .addTo(controller)
      )
    }
  }, 2000)
  return () => {
    clearTimeout(highlightStoryLink)
  }
}

export const flashImage = (triggerElement) => {
  const triggerHook = 0.25
  const controller = new ScrollMagic.Controller()
  setTimeout(() => {
    // if (document.querySelector(triggerElement)) {
    // console.log(triggerElement)
    return (
      new ScrollMagic.Scene({
        triggerElement: triggerElement,
        triggerHook: triggerHook,
        duration: document.querySelector(triggerElement).clientHeight,
        // duration: 1000,
        offset: 0,
      })
        // .setClassToggle(targetElement, "image_zoom")
        .setClassToggle(triggerElement, "image_zoom")
        // .addIndicators({ name: triggerElement })
        .addTo(controller)
    )
    // }
  }, 2000)
  return () => {
    clearTimeout(flashImage)
  }
}

export const customScrollEvent = (
  triggerElement,
  targetElement,
  classToToggle
) => {
  const triggerHook = 0.5
  const controller = new ScrollMagic.Controller()
  if (document.querySelector(triggerElement)) {
    return (
      new ScrollMagic.Scene({
        triggerElement: triggerElement,
        triggerHook: triggerHook,
        // duration: document.querySelector(triggerElement).clientHeight + 100,
        duration: 1000,
        offset: -100,
      })
        .setClassToggle(targetElement, classToToggle)
        // .addIndicators({ name: triggerElement })
        .addTo(controller)
    )
  }
  // }, loadTime)
  // return () => {
  //   clearTimeout(customEvent)
  // }
}

export const customHookScrollEvent = (
  triggerElement,
  targetElement,
  classToToggle,
  triggerHook
) => {
  // const triggerHook = hook
  const controller = new ScrollMagic.Controller()
  if (document.querySelector(triggerElement)) {
    return (
      new ScrollMagic.Scene({
        triggerElement: triggerElement,
        triggerHook: triggerHook,
        // duration: document.querySelector(triggerElement).clientHeight + 100,
        // duration: 160,
        duration: 520,
        offset: -80,
      })
        .setClassToggle(targetElement, classToToggle)
        // .addIndicators({ name: triggerElement })
        .addTo(controller)
    )
  }
  // }, loadTime)
  // return () => {
  //   clearTimeout(customEvent)
  // }
}

export const customHookScrollEventWithDuration = (
  triggerElement,
  targetElement,
  classToToggle,
  triggerHook,
  durationOfImpact
) => {
  // const triggerHook = hook
  const controller = new ScrollMagic.Controller()
  if (document.querySelector(triggerElement)) {
    return (
      new ScrollMagic.Scene({
        triggerElement: triggerElement,
        triggerHook: triggerHook,
        duration: durationOfImpact,
        offset: -80,
      })
        .setClassToggle(targetElement, classToToggle)
        // .addIndicators({ name: triggerElement })
        .addTo(controller)
    )
  }
  // }, loadTime)
  // return () => {
  //   clearTimeout(customEvent)
  // }
}

export const customHookScrollEventHeight = (
  triggerElement,
  targetElement,
  classToToggle,
  triggerHook
) => {
  // const triggerHook = hook
  setTimeout(() => {
    const controller = new ScrollMagic.Controller()
    if (document.querySelector(triggerElement)) {
      return new ScrollMagic.Scene({
        triggerElement: triggerElement,
        triggerHook: triggerHook,
        duration: document.querySelector(triggerElement).clientHeight + 0,
        // duration: 1000,
        offset: 0,
      })
        .setClassToggle(targetElement, classToToggle)
        .addIndicators({ name: triggerElement })
        .addTo(controller)
    }
  }, 3000)
  return () => {
    clearTimeout(customHookScrollEventHeight)
  }
}

export const pullContent = contentId => {
  if (document !== null) {
    return document.querySelector(contentId).scrollIntoView({
      behavior: "smooth",
      block: "start",
    })
  }
}

// import { TweenMax, TimelineMax } from 'gsap'
// import "scrollmagic/scrollmagic/minified/plugins/debug.addIndicators.min.js"
// import "scrollmagic/scrollmagic/minified/plugins/animation.gsap.min.js"
// import { defaultImageTreatment } from "../components/image-treatment.js"
